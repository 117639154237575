<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="62.5%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-row>
        <a-col :span="item.type === 'checkbox'?5:20" v-for="(item, index) of formData" :key="index" >
            <a-form-item :label="item.type === 'checkbox'?' ':item.label" :colon="item.type === 'checkbox'?false:true" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input v-if="item.type === 'input'" placeholder="请输入" v-decorator="[item.name, { rules: item.rules }]"/>
                <a-input-number v-if="item.type === 'number'" placeholder="请输入" v-decorator="[item.name, { rules: item.rules }]" style='width:100%' :min="1"/>
                <div v-if="item.type === 'upload'" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
                <LImg v-if="imageUrl" :src="imageUrl" style="width: 100px; height: 100px;"/>
                <div v-if="imageUrl" class="close" @click="closeImg($event)">&times;</div>
                <a-button v-if="!imageUrl" type="dashed" icon="file-image">选择图片</a-button>
                </div>
                <a-select v-else-if="item.type === 'select'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
                    <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
                </a-select>
                <a-checkbox v-else-if="item.type === 'checkbox'" :checked="seleteItems[item.name] == 1" @change="onChange(item.name)">{{item.label}}</a-checkbox>
                <a-radio-group v-else-if="item.type === 'radio'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
                    <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
                </a-radio-group >
                <a-textarea v-else-if="item.type === 'textarea'" placeholder="请输入"  v-decorator="[item.name, { rules: item.rules }]"/>
                <!-- <l-editor v-else-if="item.type === 'textarea2'" name="content" :data="editor.content"/> -->
            </a-form-item>
        </a-col>
      </a-row>
      
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'app_icon',
    label: '应用icon',
    type: 'upload',
    rules: [{ required: true, message: '请选择选择图片!' }]
  },
  {
    name: 'app_name',
    label: '应用名称',
    type: 'input',
    rules: [{ required: true, message: '请输入应用名称' }],
  },
  {
    name: 'app_id',
    label: 'Module ID',
    type: 'input',
    rules: [{ required: true, message: '请输入Module ID' }],
  },
  {
    name: 'app_tag',
    label: '应用标签',
    type: 'input',
    rules: [],
  },
  {
    name: 'app_desc',
    label: '应用描述',
    type: 'textarea',
    rules: [{ required: true, message: '请输入应用描述' }]
  },
    {
    name: 'app_type',
    label: '应用类型',
    type: 'radio',
    rules: [{ required: true, message: '请选择应用类型!' }],
    items: {
        data: 'typeList',
        label: 'label',
        value: 'value'
    }
  },
  {
    name: 'app_is_core',
    label: '核心应用',
    type: 'checkbox',
    rules: [],
  },
  {
    name: 'app_is_new',
    label: '是否新应用',
    type: 'checkbox',
    rules: [],
  },
  {
    name: 'app_is_miniapp',
    label: '是否小程序',
    type: 'checkbox',
    rules: [],
  },
  {
    name: 'app_is_customize',
    label: '定制化应用',
    type: 'checkbox',
  },
  {
    name: 'app_sort',
    label: '应用排序',
    type: 'number',
    rules: []
  },
  {
    name: 'app_price',
    label: '应用价钱',
    type: 'number',
    rules: []
  },
  {
    name: 'app_status',
    label: '应用状态',
    type: 'radio',
    rules: [],
    items: {
        data: 'isStatus',
        label: 'label',
        value: 'value'
    }
  }

]
import ImageModal from '@/views/modal/images'
export default {
  name: 'EditModal',
  inject: ['parent'],
  provide() {
    return {
        parent: this
    }
  },
  props: {
    typeList: Array,
    isEdit: Number,
    item:Object || null
  },
  components:{ImageModal},
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      ModalTitle: '新增应用',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      imageUrl: '',
      imagesVisible: false,
      seleteItems:{
        typeList:[],
        isStatus:[
            { label: '开启', value: 1 },
            { label: '禁用', value: 0 },
        ],
        app_is_core:0,
        app_is_new:0,
        app_is_miniapp:0,
        app_is_customize:0
      }
    }
  },

  async created() {
    this.seleteItems.typeList = this.typeList
    this.visible = true
  },
  async mounted(){
      await this.$nextTick()
      if(this.item){
          this.ModalTitle = '编辑应用'
        this.form.setFieldsValue({
            app_name:this.item.app_name,
            app_id:this.item.app_id,
            app_desc:this.item.app_desc,
            app_type:this.item.app_type,
            app_sort:this.item.app_sort,
            app_price:this.item.app_price,
            app_status:this.item.app_status,
            app_tag:this.item.app_tag,
        })
        this.seleteItems.app_is_core = this.item.app_is_core
        this.seleteItems.app_is_new = this.item.app_is_new
        this.seleteItems.app_is_miniapp = this.item.app_is_miniapp
        this.seleteItems.app_is_customize = this.item.app_is_customize
        this.imageUrl = this.item.app_icon
      }else{
          this.form.setFieldsValue({app_status:1})
      }
  },
  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async handleOk() {
      if(!this.imageUrl){
          this.$message.warning('请选择图片!!')
          return false
      }
      try {
        const { seleteItems, form , imageUrl , item} = this
        let params = await form.validateFields()
        this.confirmLoading=true;
        if (params) {
            params.app_is_core = seleteItems.app_is_core
            params.app_is_new = seleteItems.app_is_new
            params.app_is_miniapp = seleteItems.app_is_miniapp
            params.app_is_customize = seleteItems.app_is_customize
            params.app_icon = this.imageUrl
            if(item){
                params.id = item.id
            }
            let res = await this.$store.dispatch(item?'enterpriseMarketUPdateAction':'enterpriseMarketAddAction', {data:params})
                .then(res=>{
                    form.resetFields()
                    this.$message.success('操作成功')
                    this.parent.hideEditModal(1)
                })
                .catch(err=>{
                    this.confirmLoading=false;
                })

        }
      } catch {
      }
    },
    handleCancel() {
      this.parent.hideEditModal()
    },
    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        this.imageUrl = image.path
      }
      this.imagesVisible = false
    },
    onChange(e){
        if(this.seleteItems[e] == 0){
            this.seleteItems[e] = 1
        }else{
            this.seleteItems[e] = 0
        }
    }
  }
}
</script>
