<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>企业管理</a-breadcrumb-item>
            <a-breadcrumb-item>应用管理</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box" style='min-height:500px;position:relative'>
            <div style="position:absolute;right:15px;top:15px;z-index:10">
                <a-form layout='inline'>
                    <a-form-item>
                        <a-button type="primary" icon="plus" @click="showEditModal()">新增</a-button>
                    </a-form-item>
                </a-form>
            </div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <div v-for="(value, key) in list" :key='key' class="appModule">
                <div style="margin:15px 0">{{key}}</div>
                <a-row :gutter="[16,16]">
                    <a-col v-for='item in value' :key='item.id' :span="4" @click="showEditModal(item)">
                        <a-card :class="item.app_status == 0?'appModule-card':''" hoverable>
                            <LImg :src="item.app_icon" style="width: 50px; height: 50px;"/>
                            <span style="margin-left:20px">{{item.app_name}}</span>
                            <div class="appModule-icon">
                                <a-icon type="edit" />
                            </div>
                        </a-card>
                    </a-col>
                </a-row>
            </div>
        </div>
        <editModal v-if='isEditModal' :item='modalData' :typeList='typeList' />
    </div>
</template>

<script>
import editModal from './editModal'
    export default {
        name:"appModule",
        provide() {
            return {
            parent: this
            }
        },
        components:{editModal},
        data() {
            return {
                loading:false,
                list: [],
                isEditModal:false,
                typeList:[],
                modalData:null
            }
        },
        created () {
            this.getList()
        },
        methods: {
            async getList() {
                this.loading = true
                let res = await this.$store.dispatch('enterpriseMarketAction', {})
                this.list = res.data.module
                let arr = []
                for(let key  in this.list){
                    arr.push({label:key,value:this.list[key][0].app_type})
                }
                this.typeList = arr
                this.loading = false
            },
            toOpen(id){
                let that = this
                this.$confirm({
                    title: `您确定开启该应用？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('enterpriseCompanyEnableModuleAction', { data: {wid:that.$route.params.id,module_id:id} })
                        if (res) {
                        that.getList()
                        that.$message.success('操作成功~')
                        resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            },
            toClose(id){

                let that = this
                this.$confirm({
                    title: `您确定禁用该应用？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('enterpriseCompanyDisableModuleAction', { data: {wid:that.$route.params.id,module_id:id} })
                        if (res) {
                        that.getList()
                        that.$message.success('操作成功~')
                        resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            },
            showEditModal(item){
                this.modalData = item
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                    this.getList()
                }
                this.isEditModal = false
            },
        },
    }
</script>

<style lang="scss">
    .appModule{
        position: relative;
        &-icon{
            position: absolute;
            right: 12px;
            top:0px;
        }
        &-card{
            background-color: #eee;
        }
        .ant-card-body{
            padding: 12px;
        }
    }
</style>